import React from "react";
import Logo from '../../assets/logo.png'

export default function AboutRegistration() {
  return (
    // <div>
    //     <img src="" alt="" />
    //     <h4 className='my-5'>
    //         The Youth Connect Initiative Program to provide underserved youths
    //         with employability skills and connect opportunities through sponsored
    //         and subsidised programs
    //     </h4>
    //     <h5>Criteria to enroll</h5>

    //     <ol className='ps-0'>
    //         <li>
    //             <p>At least a school leaving certificate</p>
    //         </li>
    //         <li>
    //             <p>Must be between 18 to 35 years old</p>
    //         </li>
    //         <li>
    //             <p>Must have access to an Internet enabled phone</p>
    //         </li>
    //         <li>
    //             <p>Must have a valid reason for wanting to join</p>
    //         </li>
    //         <li>
    //             <p>
    //                 Must have a valid means of Identification - NIN, Voter's card,
    //                 driver's license or bank account details.
    //             </p>
    //         </li>
    //     </ol>
    // </div>
    <div className=""style={{paddingTop:"90px"}}>
    <div className="ps-md-5 ms-md-1" style={{textAlign: 'center'}}>
      <img
        src={Logo}
        alt=""
        className=""
        // style={{ marginBottom: "40px" }}
        style={{ margin: "0px auto 50px", textAlign: "center", width: '', border: '' }}
        width="290px"
      />{" "}
      <h4
      // style={{
      //   // backgroundColor: "#F9DCAD",
      //   background: '#f3c13a',
      //   fontSize: "25px",
      //   color: 'white',
      //   padding: '.3em .4em',
      //   width: 'fit-content'
      //   // margin: "100px 0px",
      // }} 
      style={{
        // backgroundColor: "#F9DCAD",
        background: 'darkgreen',
        fontSize: "22px",
        color: 'white',
        padding: '.5em .4em',
        width: 'fit-content',
        textAlign: 'left',
        margin: 'auto'
      }} 
      >Partner With Us</h4>
      <br />
      {/* <small
        style={{
          backgroundColor: "#F9DCAD",
          fontSize: "15px",
          margin: "100px 0px",
        }}
        className="mt-5 py-2 px-2 rounded-2"
      >
        Introducing the 3 Million Technical Talent Program
      </small> */}
      {/* <h4 className="my-4">
      Partner with Us
      </h4> */}
      <h5 style={{textAlign: 'left'}}>Are you interested in contributing to building the technical talent backbone that will drive the growth of Nigeria’s digital economy? </h5> 
       <br />
      <h5 style={{textAlign: 'left'}}>You can partner with us in some of the following ways: </h5> 
      <br />

      <ul className="ps-md-0" style={{lineHeight: '1.2em', textAlign: 'left'}}>
        <li>
          <p>Direct or indirect funding to train technical talent</p>
        </li>

        <li>
          <p>Equipment, physical spaces & other infrastructural support</p>
        </li>

        <li>
          <p>Device support </p>
        </li>
       
<li>
    <p>Local & international job placements</p>
</li>
        <li>
          <p> Support for programme execution (Financial & Non-financial)</p>
        </li>
        <li>
          <p>
          And more
          </p>
        </li>
      </ul>
      <br />
      <div style={{ textAlign: "left" }}>
          <a
            href="https://3mtt.nitda.gov.ng/"
            className="py-2 mt-4"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#007d53",
              borderBottom: "1px solid #007d53",
            }}
            rel="noreferrer"
          >
            About the 3MTT Programme
          </a>
        </div>
    </div>
    </div>
  );
}
