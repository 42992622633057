import React, { useState } from "react";
import toast from "react-hot-toast";
import premblyLogo from "../../assets/premblyLogo.png";
import Alert from "./Alert";

export default function Mainpage() {
    // const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gender, setGender] = useState("");
  const [contribution, setContribution] = useState("");
  const [emailAddress, setEmailAdress] = useState("");
  const [partnership, setPartnership] = useState("");
  const [designation, setDesignation] = useState("");
  const [age, setAge] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [viewableLink, setViewableLink] = useState("");
  const [additionInfo, setAdditionInfo] = useState("");
  const [initiatives, setInitiatives] = useState("");
  const [benefit, setBenefit] = useState("");
  const [orgGoals, setOrgGoals] = useState("");
  const [support, setSupport] = useState("");
  const [services, setServices] = useState("");
  const [allocation, setAllocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [origin, setOrigin] = useState("");
  const [educationalLevel, setEducationalLevel] = useState("");
  const [technicalLevel, setTechnicalLevel] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [course, setCourse] = useState("");
  const [computerAccess, setComputerAccess] = useState("");
  const [internetAccess, setInternetAccess] = useState("");
  const [commitment, setCommitment] = useState("");
  const [reason, setReason] = useState("");
  const [verifMethod, setVerifMethod] = useState("");
  const [ninData, setNinData] = useState("");
  const [bvnData, setBvnData] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false)

  let nigerian_states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Federal Capital Territory",
  ];

  let educationData = [
    "Primary school leaving certificate",
    "Primary school leaving certificate",
    "Ordinary National Diploma (OND)",
    "Higher National Diploma (HND)",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctor of philosophy (PhD)",
    "No Education",
  ];

  let courseInterest = [
    "AI/ML",
    "Data Science",
    "Software Development",
    "Animation",
    "Cloud computing",
    "DevOps",
    "UI/UX",
    "Cyber Security",
    "Game Development",
    "Data Analysis & Visualization",
    "Product Management",
    "Quality Assurance",
  ];

  let next = () => {
    let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    // let url =  /^(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

    if (page === 1) {
      if (!orgName) {
        toast.error("Please input organization/company name");
        return;
      }
      if (!fullName) {
        toast.error("Please input fullname of contact person");
        return;
      }
      if (!designation) {
        toast.error("Please input contact person designationation");
        return;
      }
      if (!emailAddress) {
        toast.error("Please input email address");
        return;
      }
      if (emailAddress && (email_reg.test(emailAddress) === false)) {
        toast.error("Email Address is invalid")
        return
    }
    //   if(emailAddress && !emailAddress.test(regEx)){
    //     toast.error("Please input a valid email address");
    //     return;
    //   }
      if (!phoneNumber) {
        toast.error("Please input phone number");
        return;
      }
      if (phoneNumber && phoneNumber.length !== 11) {
        toast.error("Invalid phone number");
        return;
      }
      if (!orgWebsite) {
        toast.error("Please input organization website");
        return;
      }
      // if (orgWebsite  && (url.test(orgWebsite) === false)) {
      //   toast.error("Please input a valid website url");
      //   return;
      // }
      setPage(2);
    }

    if (page === 2) {
        if (!partnership) {
            toast.error("Please choose partnership");
            return;
          }
    //     if (!email) {
    //     toast.error("Please input your email");
    //     return;
    //   }
    //   if (!phone) {
    //     toast.error("Please input your phone number");
    //     return;
    //   }
    //   if (!location) {
    //     toast.error("Please select your residential state");
    //     return;
    //   }
    //   if (!address) {
    //     toast.error("Please input your address");
    //     return;
    //   }
    //   if (!origin) {
    //     toast.error("Please select your state of origin");
    //     return;
    //   }
      setPage(3);
    }

    if (page === 3) {
      if (!amount) {
        toast.error("Please input amount");
        return;
      }
      if (!contribution) {
        toast.error("Please choose your contribution");
        return;
      }
      if (!allocation) {
        toast.error("Please input your allocation");
        return;
      }
      setPage(4);
    }

    if (page === 4) {
      if (!services) {
        toast.error("Please input your services");
        return;
      }
      if (!support) {
        toast.error("Please input your estimated value");
        return;
      }
    //   if (!internetAccess) {
    //     toast.error("Please confirm if your access to the internet");
    //     return;
    //   }
    //   if (!commitment) {
    //     toast.error("Please confirm your availability for the programme");
    //     return;
    //   }
    //   if (!reason) {
    //     toast.error("Please tell us your reason for joining the programme");
    //     return;
    //   }
      setPage(5);
    }

    if (page === 5) {
      if (!orgGoals) {
        toast.error("Please input your organization goals");
        return;
      }
      if (!benefit) {
        toast.error("Please input collaboration benefits");
        return;
      }
    //   if (!orgGoals) {
    //     toast.error("Please input your organization goals");
    //     return;
    //   }
    //   if (!orgGoals) {
    //     toast.error("Please input your organization goals");
    //     return;
    //   }
    //   if (!orgGoals) {
    //     toast.error("Please input your organization goals");
    //     return;
    //   }
    //   if (verifMethod === "NIN" && !ninData) {
    //     toast.error("Please input your National Identification Number");
    //     return;
    //   }
    //   if (verifMethod === "NIN" && ninData?.length !== 11) {
    //     toast.error("Invalid National Identification Number");
    //     return;
    //   }
    //   if (verifMethod === "BVN" && !bvnData) {
    //     toast.error("Please input your Bank Verification Number");
    //     return;
    //   }
    //   if (verifMethod === "BVN" && bvnData?.length !== 11) {
    //     toast.error("Invalid Bank Verification Number");
    //     return;
    //   }
    setPage(6);
}



if (page === 6){
    if (!initiatives) {
        toast.error("Please input similar initiative");
        return;
    }
    setPage(7);
    }

    if (page === 7){
        // setIsSubmitting(true)
        if (!additionInfo) {
            toast.error("Please input additional Information");
            return;
        }
        if (!viewableLink) {
            toast.error("Please input viewable link");
            return;
        }
        
        // if (viewableLink  && (url.test(viewableLink) === false)) {
        //     toast.error("Please input a valid viewable link url");
        //     return;
        //   }
        if (!agreement) {
            toast.error("Please confirm agreement");
            return;
        }

        pushAllData();
        }
  };

  let prev = () => {
    if (page === 2) {
      setPage(1);
    }
    if (page === 3) {
      setPage(2);
    }
    if (page === 4) {
      setPage(3);
    }
    if (page === 5) {
      setPage(4);
    }
    if (page === 6) {
        setPage(5);
      }
      if (page === 7) {
        setPage(6);
      }
  };

  let pushAllData = () => {
    setLoading(true);

    var raw = JSON.stringify({
    //   first_name: firstName,
    //   last_name: lastName,

    //   id_type: verifMethod,
    //   nin_bvn: verifMethod === "NIN" ? ninData : bvnData,

    //   email: email,
    //   gender: gender,
    //   age: age,
    //   phone: phone,
    //   residence: location,
    //   address: address,
    //   state_of_origin: origin,

    //   level_of_education: educationalLevel,
    //   technical_skill_level: technicalLevel,
    //   employment_status: employmentStatus,
    //   current_learning_course: course,
    //   has_computer: computerAccess,
    //   has_internet: internetAccess,
    //   five_hours: commitment,
    //   project_link: githubLink,
    //   plan: reason,

    // name: orgName,
    // state: location,
    // address: address,
    // cac: registrationNumber,
    // website: orgWebsite,
    // social_media: links,
    // poc_fullname: pocName,
    // poc_email: emailAddress,
    // poc_phone: pocPhone,
    // training_period: programmeYear,
    // staff_count: staffNumber,
    // courses: selectedCourses,
    // courses_track_record: track,
    // courses_overview: delivery,
    // courses_link: link,
    // infrastructure_proof: proof,
    // infrastructure_proof_partner: infrastructure,
    // trainee_capacity: capacity,
    // trainee_capacity_track_record: model,
    // network_mentor: network
    name: orgName,
//   state: "kk",
  contact_name: fullName,
  contact_designation: designation,
  contact_email: emailAddress,
  website: orgWebsite,
  contact_phone: phoneNumber,
  partnership_type: partnership,
  monetary_amount: amount,
  monetary_type: contribution,
  monetary_program: allocation,
  service: services,
  monetary_estimated_amount: support,
  training_goal: orgGoals,
  envision_collaboration_benefit: benefit,
  other_partner: initiatives,
  question: additionInfo,
  document_link: viewableLink,
  has_correct_info: Boolean(agreement),

    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/identity-reg-en-dev/federal/partner/register",
      requestOptions
    )
      .then((response) => response?.json())
      .then((result) => {
        console.log("success", result);
        setLoading(false);
        if (result?.status) {
            setShowAlert(true)
        //   setSuccessModal(true);
    //     setShowAlert(true)
    // setIsSubmitting(false)
    // setOrganizationName('');
    //  setLocation('');
    //  setAddress('');
    //  setRegistrationNumber('');
    //  setWebsite('');
    //  setLinks('');
    //  setPocName('');
    // setPocAddress('');
    //  setPocPhone('');
    // setProgramYear('');
    //  setStaffNumber('');
    //  setExpertiseCourse('');
    //  setTrack('');
    //  setDelivery('');
    //  setLink('');
    //  setProof('');
    //  setInfrastructure('');
    //  setCapacity('');
    //  setModel('');
    // setNetwork('')
        } else {
          toast.error(result?.detail);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.status) {
          toast.error(error?.detail);
        }
      });
  };

//   const handleClose = () => {
//     setSuccessModal(false);
//     clearData();
//     setPage(1);
//   };
const handleClose = () => {
    setShowAlert(false)
    setPage(1)
    window.location.href = 'https://3mtt.nitda.gov.ng/'
    // navigate('https://nitda.gov.ng/3mtt/#')
  }

  let clearData = () => {
    setFirstName("");
    setLastName("");
    setVerifMethod("");
    setNinData("");
    setBvnData("");
    setEmail("");
    setGender("");
    setAge("");
    setPhone("");
    setLocation("");
    setAddress("");
    setOrigin("");
    setEducationalLevel("");
    setTechnicalLevel("");
    setEmploymentStatus("");
    setCourse("");
    setComputerAccess("");
    setInternetAccess("");
    setCommitment("");
    setGithubLink("");
    setReason("");
  };

  return (
    <div>
      {successModal && <Alert handleClose={handleClose} />}
      {/* <div className="col-md-8 col-lg-5 mx-auto py-5"> */}
      <div className="py-5">
        <div
          className="card border-end-0"
          style={{ borderRadius: "70px 0px 0px 70px" }}
        >
          {/* <div className="card-header text-center" style={{ background: "rgb(0, 125, 83)" }}>
                        <img src="https://www.commtech.gov.ng/images/FMCIDE_Logo.png" width="300px" alt="" />
                    </div> */}
          <div className="card-body px-md-4">
            <div className="row" style={{alignItems: 'center'}}>
              <div className="col-md-4 partner-step">
                <h5>Partner Information</h5>

                <div className="d-flex process-tag">

                  <p style={{border: page > 1 ? '1px solid green' : '', width: '', height: ''}}>{page > 1 ? <span style={{color: 'green'}}>&#x2713;</span> : 1}</p>
                  {/* <span><i className="ri-check-line"></i></span> */}
                  <div className="desc">
                    <h6>Organisation Data</h6>
                    {/* <small>
                      Few informations about you: Name, Gender, Email ...
                    </small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p style={{border: page > 2 ? '1px solid green' : ''}}>{page > 2 ? <span style={{color: 'green'}}>&#x2713;</span> : 2}</p>
                  <div className="desc">
                    <h6>Partnership Type</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p className="step-3" style={{border: page > 3 ? '1px solid green' : ''}}>{page > 3 ? <span style={{color: 'green'}}>&#x2713;</span> : 3}</p>
                  <div className="desc">
                    <h6>Monetary Support (if applicable)</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p className="step-4" style={{border: page > 4 ? '1px solid green' : '', width: '30px !important'}}>{page > 4 ? <span style={{color: 'green'}}>&#x2713;</span> : 4}</p>
                  <div className="desc">
                    <h6>Service-Based Support (if applicable)</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p style={{border: page > 5 ? '1px solid green' : ''}}>{page > 5 ? <span style={{color: 'green'}}>&#x2713;</span> : 5}</p>
                  <div className="desc">
                    <h6>Partnership Goals</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p className="step-6" style={{border: page > 6 ? '1px solid green' : ''}}>{page > 6 ? <span style={{color: 'green'}}>&#x2713;</span> : 6}</p>
                  <div className="desc">
                    <h6>Previous Partnership Experience</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{background: ''}}>
                  <p style={{border: page > 7 ? '1px solid green' : ''}}>{page > 7 ? <span style={{color: 'green'}}>&#x2713;</span> : 7}</p>
                  <div className="desc">
                    <h6>Additional Information</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

              </div>
              <div className="col-md-1">
                <hr style={{border: '1px solid black', height: '70vh', width: '1px'}} />
              </div>
              <div className="col-md-7">
                <div className="form-div">
                  {page === 1 && (
                    <>
                      <div className="mt-5">
                        <fieldset className="form-control">
                          <legend>Organization/Company Name</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={orgName}
                              maxLength={250}
                              onChange={(e) => {
                                setOrgName(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>

                        {/* <label htmlFor="firstname"><h6>First Name</h6></label>
                                                <input type="text" className='form-control' value={firstName}
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value)
                                                    }}
                                                /> */}
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Full name of Contact Person</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={fullName}
                              onChange={(e) => {
                                setFullName(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Contact Person's designation</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={designation}
                              onChange={(e) => {
                                setDesignation(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Email address of Contact Person</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={emailAddress}
                              onChange={(e) => {
                                setEmailAdress(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Contact Person's Phone Number</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="number"
                              maxLength={11}
                              className="w-100"
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Organization/Company website</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={orgWebsite}
                              onChange={(e) => {
                                setOrgWebsite(e.target.value);
                              }}
                              placeholder="www.website.com"
                            />
                          </span>
                        </fieldset>
                      </div>

                      {/* <div className='mt-4'>
                                                <fieldset className="form-control">
                                                    <legend>Select Gender</legend>
                                                    <span className="d-flex align-items-center ">
                                                        <i className="ri-mail-line" />
                                                        <select className='w-100' value={gender} onChange={(gender) => setGender(gender.target.value)}>
                                                            <option value="">Select Gender </option>
                                                            <option value="male">Male </option>
                                                            <option value="Femaile">Female </option>
                                                        </select>
                                                    </span>
                                                </fieldset>
                                            </div> */}

                      {/* <div className='mt-4'>
                                                <fieldset className="form-control">
                                                    <legend>Age Range</legend>
                                                    <span className="d-flex align-items-center ">
                                                        <i className="ri-mail-line" />
                                                        <select className='w-100' value={age} onChange={(age) => setAge(age.target.value)}>
                                                            <option value="">Select age range </option>
                                                            <option value="< 18">Less than 18 years</option>
                                                            <option value="18-25">18 - 25 years </option>
                                                            <option value="26-35">26 - 35 years </option>
                                                            <option value="36-45">36- 45 years </option>
                                                            <option value="> 45">Above 45  </option>
                                                        </select>
                                                    </span>
                                                </fieldset>
                                            </div> */}
                    </>
                  )}
                  {page === 2 && (
                    <>
                      <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>Type of Partnership (select one)</h6>
                        </label>
                        {[
                          "Monetary Support",
                          "Service-Based Support",
                          "Combination of Both",
                        ].map((trainee) => (
                          <div className="input-group align-items-center">
                            <input
                              type="radio"
                              name="monetary"
                              value={trainee}
                              onChange={(e) => setPartnership(e.target.value)}
                            />
                            <p className="mt-3 ms-2">{trainee}</p>
                          </div>
                        ))}
                      </div>
                      {/* <div className='mt-5'>
                                                <label htmlFor="email"><h6>Email</h6></label>
                                                <input type='email' className='form-control' value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <label htmlFor="phoneNumber"><h6>Phone number</h6></label>
                                                <input type='tel' className='form-control' value={phone}
                                                    onChange={(e) => {
                                                        setPhone(e.target.value)
                                                    }}
                                                />
                                            </div> */}

                      {/* <div className='mt-4'>
                                                <label htmlFor="gender"><h6>What state do you reside  ?</h6></label>
                                                <select className='form-select' value={location} onChange={(e) => setLocation(e.target.value)}>
                                                    <option value="">Select state. </option>
                                                    {nigerian_states?.map((val, i) => (
                                                        <option value={val}>{val} </option>
                                                    ))}

                                                </select>
                                            </div> */}
                      {/* <div className='mt-4'>
                                                <label htmlFor="address"><h6>Address</h6></label>
                                                <input type='tel' className='form-control' value={address}
                                                    onChange={(e) => {
                                                        setAddress(e.target.value)
                                                    }}
                                                />
                                            </div> */}

                      {/* <div className='mt-4'>
                                                <label htmlFor="gender"><h6>State of Origin  ?</h6></label>
                                                <select className='form-select' value={origin} onChange={(e) => setOrigin(e.target.value)}>
                                                    <option value="">Select state. </option>
                                                    {nigerian_states?.map((val, i) => (
                                                        <option value={val}>{val} </option>
                                                    ))}

                                                </select>
                                            </div> */}
                    </>
                  )}
                  {page === 3 && (
                    <>
                      <div className="mt-4">
                      <fieldset className="form-control">
                          <legend>Please specify the amount you are willing to contribute to the 3MTT programme (NGN)</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="number"
                              maxLength={250}
                              className="w-100"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                        {/* <label htmlFor="educationalLevel">
                          <h6>Please specify the amount you are willing to contribute to the training programme (NGN)</h6>
                        </label> */}
                        {/* <select
                          className="form-select"
                          value={educationalLevel}
                          onChange={(e) => setEducationalLevel(e.target.value)}
                        >
                          <option value="">Choose Educational level. </option>
                          {educationData?.map((val, i) => (
                            <option key={i} value={val}>
                              {val}{" "}
                            </option>
                          ))}
                        </select> */}
                      </div>

                      <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>What type of monetary contribution?</h6>
                        </label>
                        {[
                          "One-time donation (NGN)",
                          "Periodic contribution (NGN)",
                        ].map((trainee) => (
                          <div className="input-group align-items-center">
                            <input
                              type="radio"
                              name="contribution"
                              value={trainee}
                              onChange={(e) => setContribution(e.target.value)}
                            />
                            <p className="mt-3 ms-2">{trainee}</p>
                          </div>
                        ))}
                      </div>

                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>How would you like your contribution to be allocated within the 3MTT programme?</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={allocation}
                              onChange={(e) => {
                                setAllocation(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>

                      {/* <div className="mt-4">
                        <label htmlFor="skillLevel">
                          <h6>
                            What technical skill level do you think you are
                            currently at ?
                          </h6>
                        </label>
                        <select
                          className="form-select"
                          value={technicalLevel}
                          onChange={(e) => setTechnicalLevel(e.target.value)}
                        >
                          <option value="">
                            Choose your technical skill level
                          </option>

                          <option value="Beginner level (Zero 0 or less than 1year programmeming experience)">
                            Beginner level (Zero 0 or less than 1year
                            programmeming experience)
                          </option>
                          <option value="Intermediate level (1 to 4 years programmeming experience)">
                            Intermediate level (1 to 4 years programmeming
                            experience)
                          </option>
                          <option value="Advanced level (4+ years of programmeming experience)">
                            Advanced level (4+ years of programmeming experience)
                          </option>
                          <option value="Expert level (7+ years of programmeming experience)">
                            Expert level (7+ years of programmeming experience)
                          </option>
                        </select>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="employmentStatus">
                          <h6>What is your employment status ?</h6>
                        </label>
                        <select
                          className="form-select"
                          value={employmentStatus}
                          onChange={(e) => setEmploymentStatus(e.target.value)}
                        >
                          <option value="">Select employment status. </option>

                          <option value="Student">Student</option>
                          <option value="Unemployed">Unemployed</option>
                          <option value="Employed (part time)">
                            Employed (part time)
                          </option>
                          <option value="Employed (Full time)">
                            Employed (Full time)
                          </option>
                          <option value="Self employed (Business Owner)">
                            Self employed (Business Owner)
                          </option>
                        </select>
                      </div> */}
                    </>
                  )}

                  {page === 4 && (
                    <>
                    <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Please describe the services your organization can provide to support the training programme</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={services}
                              onChange={(e) => {
                                setServices(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Estimated value (in NGN monetary terms) of the service-based support you are offering</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="tel"
                              className="w-100"
                              value={support}
                              maxLength={250}
                              onChange={(e) => {
                                setSupport(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>



                      {/* <div className="mt-4">
                        <label htmlFor="course">
                          <h6>What course are you interested in learning ?</h6>
                        </label>
                        <select
                          className="form-select"
                          value={course}
                          onChange={(e) => setCourse(e.target.value)}
                        >
                          <option value="">Choose Course Interest. </option>
                          {courseInterest?.map((val, i) => (
                            <option key={i} value={val}>
                              {val}{" "}
                            </option>
                          ))}
                        </select>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="computerAccess">
                          <h6>
                            Do you own or have access to a computer for this
                            programme?
                          </h6>
                        </label>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="computerAccess"
                            value="Yes"
                            onChange={(e) => setComputerAccess(e.target.value)}
                          />
                          <p className="mt-3 ms-2">Yes</p>
                        </div>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="computerAccess"
                            value="No"
                            onChange={(e) => setComputerAccess(e.target.value)}
                          />
                          <p className="mt-3 ms-2">No</p>
                        </div>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="internetAccess">
                          <h6>
                            Do you have access to quality internet for this
                            programme?
                          </h6>
                        </label>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="internetAccess"
                            value="Yes"
                            onChange={(e) => setInternetAccess(e.target.value)}
                          />
                          <p className="mt-3 ms-2">Yes</p>
                        </div>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="internetAccess"
                            value="No"
                            onChange={(e) => setInternetAccess(e.target.value)}
                          />
                          <p className="mt-3 ms-2">No</p>
                        </div>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="commitment">
                          <h6>
                            Are you able to commit to 5+ hours per week on this
                            programme?
                          </h6>
                        </label>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="commitment"
                            value="Yes"
                            onChange={(e) => setCommitment(e.target.value)}
                          />
                          <p className="mt-3 ms-2">Yes</p>
                        </div>
                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="commitment"
                            value="No"
                            onChange={(e) => setCommitment(e.target.value)}
                          />
                          <p className="mt-3 ms-2">No</p>
                        </div>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="reason">
                          <h6>
                            Tell us what you plan to accomplish by joining this
                            programme
                          </h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </div> */}
                    </>
                  )}

                  {page === 5 && (
                    <>
                     <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Please describe your organization's goals and objectives in partnering with us for this 3MTT programme</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={orgGoals}
                              maxLength={250}
                              onChange={(e) => {
                                setOrgGoals(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>How do you envision our collaboration benefiting both parties?</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={benefit}
                              maxLength={250}
                              onChange={(e) => {
                                setBenefit(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>

                      {/* <div className="mt-5">
                        <label htmlFor="course">
                          <h6>
                            Select how you want us to verify your information
                          </h6>
                        </label>
                        <select
                          className="form-select"
                          value={verifMethod}
                          onChange={(e) => setVerifMethod(e.target.value)}
                        >
                          <option value="">Choose verification method. </option>
                          <option value="BVN">
                            {" "}
                            Bank Verification Number (BVN)
                          </option>
                          <option value="NIN">
                            {" "}
                            National Identity Number (NIN){" "}
                          </option>
                        </select>
                      </div> */}

                      {/* <>
                        {verifMethod === "NIN" && (
                          <div className="mt-4">
                            <label htmlFor="nin">
                              <h6>NIN</h6>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              value={ninData}
                              onChange={(e) => {
                                setNinData(e.target.value);
                              }}
                            />
                          </div>
                        )}

                        {verifMethod === "BVN" && (
                          <div className="mt-4">
                            <label htmlFor="bvn">
                              <h6>BVN</h6>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              value={bvnData}
                              onChange={(e) => {
                                setBvnData(e.target.value);
                              }}
                              placeholder="12345678909"
                            />
                          </div>
                        )}
                      </> */}

                      {/* <div className="mt-4">
                        <label htmlFor="githubLink">
                          <h6>
                            Share a link to projects you've previously worked
                            on. (e.g a viewable link to your projects, Github
                            profile, bitbucket profile etc)
                          </h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={githubLink}
                          onChange={(e) => {
                            setGithubLink(e.target.value);
                          }}
                          placeholder="https://github.com/testgithublink"
                        />
                      </div> */}
                    </>
                  )}
                  {page === 6 && (
                    <>
                    <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Have you partnered with other organizations for similar initiatives in the past?</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={initiatives}
                              maxLength={250}
                              onChange={(e) => {
                                setInitiatives(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                    </>
                  )}
                  {page === 7 && (
                    <>
                         <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Are there any additional information or questions you would like to share with us regarding this partnership opportunity?</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={additionInfo}
                              maxLength={250}
                              onChange={(e) => {
                                setAdditionInfo(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Please share a viewable link to relevant documents such as brochures, proposals, or other supporting materials to this application (if applicable)</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={viewableLink}
                              onChange={(e) => {
                                setViewableLink(e.target.value);
                              }}
                              placeholder="www.website.com"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>By submitting this application, you confirm that the information provided is accurate and that your organization is interested in exploring a partnership with the Federal Ministry of Communications, Innovation and Digital Economy for the 3MTT programme.</h6>
                        </label>
                      
                          <div className="input-group align-items-center">
                            <input
                              type="radio"
                              name="computerAccess"
                              value={true}
                              onChange={(e) => setAgreement(e.target.value)}
                            />
                            <p className="mt-3 ms-2">Yes, I confirm and agree to the above statement</p>
                          </div>
                    
                      </div>

                    </>
                  )}
                  <div className="mb-3 text-center">
                    <div className="d-flex mb-5 justify-content-between">
                      <button
                        className="btn btn-blue-outline py-3 mt-5"
                        style={{ width: "47%" }}
                        onClick={prev}
                        disabled={page < 2 || loading ? true : false}
                      >
                        {" "}
                        Previous{" "}
                      </button>
                      {/* <button
                        className="btn btn-blue px-4 py-3 mt-5"
                        style={{ width: "47%" }}
                        onClick={next}
                        disabled={isSubmitting || (!agreement && page === 7) ? true :  false}
                      >
                        {page === 7 && !isSubmitting ? 'Submit' : page === 7 && isSubmitting ? 'Loading...' : 'Next'}
                      </button> */}
                      <button className='btn btn-blue px-4 py-3 mt-5' style={{ width: "47%" }} onClick={next} disabled={(loading || (!agreement && page === 7)) ? true : false}>
                                                {page === 7 ?
                                                    <>
                                                        {loading ?
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                            </>
                                                            :
                                                            <>
                                                                Submit
                                                            </>
                                                        }
                                                    </>
                                                    : "Next"
                                                }
                                            </button>
                    </div>
                    <p>
                      Powered by{" "}
                      <img src={premblyLogo} style={{ width: "80px" }} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && 
      <Alert handleClose={handleClose} />
      }
    </div>
  );
}
