import React from 'react'

export default function Footer() {
  return (
    <div className='footer' style={{borderTop: '1px solid black', borderBottom: '1px solid black', width: '90%', margin:'1em auto', padding: '.8em 0px', textAlign: 'center', textDecoration: 'none'}}>
      <ul >
        <a href='https://prembly.com/Policy' target='_blank' rel="noreferrer">
        <li>Privacy Policy</li>
        </a>
        <a href='https://prembly.com/terms' target='_blank' rel="noreferrer">
        <li>Terms Of Service</li>
        </a>
        <a href='https://prembly.com/dataProtection' target='_blank' rel="noreferrer">
        <li>Data Protection Policy </li>
        </a>
        <a href='https://prembly.com/Policy' target='_blank' rel="noreferrer">
        <li>Manage Cookie Preference</li>
        </a>
        {/* <a href='https://prembly.com/Policy' target='_blank' rel="noreferrer"> */}
        <li>Ⓒ Prembly. Inc. All Rights Reserved. 2023</li>
        {/* </a> */}
      </ul>
    </div>
  )
}
