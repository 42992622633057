import { Toaster } from "react-hot-toast";
import Mainpage from "./components/mainpage";
import AboutRegistration from "./components/about";
import Footer from "./components/Footer";

function App() {
  return (
    <>
    <div className="App">
        <div className="container-fluid m-0">
        <Toaster position="top-right" reverseOrder={false}/>
            <div className="row">
                <div className=" col-md-4 offset-md-1">
                    <AboutRegistration/>
                </div>
                <div className="col-md-7">
                    <Mainpage/>
                </div>
            </div>
        </div>
    <Footer />
    </div>
    </>
  );
}

export default App;
